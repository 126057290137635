import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  InputNumber,
  Modal,
  Select,
  Spin,
  Tabs,
  Typography,
  Upload,
  message,
  notification,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

import UPM from "../assets/images/upm-logo.png";

const Home = () => {
  const navigate = useNavigate();

  // const {
  //   files,
  //   fileNames,
  //   fileTypes,
  //   totalSize,
  //   totalSizeInBytes,
  //   handleDragDropEvent,
  //   clearAllFiles,
  //   createFormData,
  //   setFiles,
  //   removeFile,
  // } = useFileUpload();

  const [training, setTraining] = useState(80);
  const [test, setTest] = useState(20);
  const [attributes, setAttributes] = useState(10);
  const [instances, setInstances] = useState(1000);

  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState();
  const [table, setTable] = useState("");

  const [datasetFeatures, setDatasetFeatures] = useState();
  const [datasetInstances, setDatasetInstances] = useState();
  const [dataType, setDataType] = useState();
  const [missingRatio, setMissingRatio] = useState();
  const [dataSize, setDataSize] = useState();

  const fileReader = new FileReader();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { Option } = Select;
  const { TabPane } = Tabs;
  const { Text, Link } = Typography;

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onChange = (key) => {
    console.log(key);
  };

  const onChangeCheckBox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onChangeTrainingSet = (value) => {
    setTraining(value);
    console.log("changed", value);
  };

  const onChangeTestSet = (value) => {
    setTest(value);
    console.log("changed", value);
  };

  const onChangeAttributesSet = (value) => {
    setAttributes(value);
    console.log("changed", value);
  };

  const onChangeInstancesSet = (value) => {
    setInstances(value);
    console.log("changed", value);
  };

  // MODAL
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openNotification = () => {
    notification.open({
      message: "Coming Soon",
      description:
        "The iExtraImpute Application is currently under development, soon you will be able to use the best imputation method for missing values.",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  const runScript = async () => {
    window.pyName = "Mustafa";

    // df = pd.read_csv('/screening.csv', dtype='unicode')

    const pyScript = `
    import numpy as np
    import pandas as pd
    import js
    pyName = js.pyName
    print(pyName)
    `;

    async function main() {
      let pyodide = await window.pyodide;
      await pyodide.loadPackage(["pandas", "numpy"]);
      pyodide.runPython(pyScript);
    }
    main();

    // const pyodide = await window.loadPyodide({
    //   indexURL : "https://cdn.jsdelivr.net/pyodide/v0.18.1/full/"
    // });

    // return await pyodide.runPythonAsync(code);
  };

  // const handleChangeFile = (info) => {
  //   console.log(info.file)
  //   if (info.file.status === 'uploading') {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === 'error') {
  //     setLoading(false);
  //     console.log(info.file.error.message)
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.

  //     // setLoading(false);
  //     fileReader.onload = function (event) {
  //       setLoading(false);
  //       const csvOutput = event.target.result;
  //       console.log(csvOutput)
  //       setDataset(csvOutput)

  //       // console.log('csvOutput', csvOutput)
  //     };

  //     fileReader.readAsText(info.file.originFileObj);
  //     // getBase64(info.file.originFileObj, (url) => {
  //     //   setLoading(false);
  //     //   setImageUrl(url);
  //     // });
  //   }
  // };

  // const uploadButton = (
  //   <div>
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );

  // const handleSubmitFile = async (e) => {
  //   e.preventDefault();

  //   const formData = createFormData();

  //   for (const [key, value] of formData.entries()) {
  //     console.log(`${key}: ${value}`)
  //   }

  //   // console.log('Success:', formData);

  //   try {
  //     axios.post('http://127.0.0.1:5000/imputex', formData, {
  //       'content-type': 'multipart/form-data',
  //     })
  //     .then(res => console.log(res))
  //     .catch(err => console.log(err));
  //   } catch (error) {
  //     console.error('Failed to submit files.');
  //   }
  // };

  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done" || info.file.status === "error") {
        message.success(`${info.file.name} file uploaded successfully`);
        setDataset(info.file.originFileObj);
        console.log("originFileObj", info.file.originFileObj);
      }
    },
  };

  const handleImpute = () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("dataset", dataset);
      // .post("https://autoimpute-93lt.onrender.com/impute", formData, {
      axios
        .post("http://172.16.243.226:80/impute", formData, {
          "content-type": "multipart/form-data",
        })
        .then((res) => {
          setTable(res.data);
          console.log("res", res.data);
          setLoading(false);
          message.success(
            `The dataset ${dataset.name} has been imputed successfully!`
          );
          // saveAs(res.data, "df.csv");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error(
            "Ooops.. it seems something went wrong! Please try again."
          );
        });
    } catch (error) {
      console.error("Failed to submit files.");
      setLoading(false);
      message.error("Ooops.. it seems something went wrong! Please try again.");
    }
  };

  //   const handleAutoImpute = () => {
  //     setLoading(true);
  //     try {
  //       // let formData = new FormData()
  //       // formData.append('dataset', dataset)
  //       axios
  //         .post("http://127.0.0.1:5000/autoimpute")
  //         .then((res) => {
  //           // setTable(res.data)
  //           // console.log('res', res.data)
  //           setLoading(false);
  //           message.success(`The autonomous imputation has been stopped!`);
  //           // saveAs(res.data, "df.csv");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setLoading(false);
  //           message.error(
  //             "Ooops.. it seems something went wrong! Please try again."
  //           );
  //         });
  //     } catch (error) {
  //       console.error("Failed to submit files.");
  //       setLoading(false);
  //       message.error("Ooops.. it seems something went wrong! Please try again.");
  //     }
  //   };

  // PARSE STRING TO HTML
  const support = (function () {
    if (!window.DOMParser) return false;
    var parser = new DOMParser();
    try {
      parser.parseFromString("x", "text/html");
    } catch (err) {
      return false;
    }
    return true;
  })();

  const stringToHTML = (str) => {
    // If DOMParser is supported, use it
    if (support) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(str, "text/html");
      return doc.body;
    }

    // Otherwise, fallback to old-school method
    var dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  return (
    <div>
      <div>
        <title>AutoImputeX | UPM</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </div>

      <main>
        {/* <Nav /> */}
        <Spin size="large" spinning={loading} tip="Imputing missing values...">
          <div className="flex justify-center items-center w-full h-screen bg-cyan-600">
            <div className="flex flex-col w-11/12 sm:h-5/6 py-5 bg-white shadow-xl rounded-sm">
              <div className="flex flex-row px-14 py-8 justify-between w-full">
                <div className="animate-pulse text-2xl" onClick={runScript}>
                  AutoImputeX
                </div>

                {/* <div
                dangerouslySetInnerHTML={{
                  __html: `<py-config type="json">
                          {
                            "packages": ["numpy", "pandas"]
                          }
                          </py-config>
                          <py-script>
                            import numpy as np
                            import pandas as pd
                            df = pd.read_csv('data/exp3/screening.csv', dtype='unicode')
                            a = np.array([[1, 2, 3, 4], [5, 6, 7, 8], [9, 10, 11, 12]])
                            print(a)
                          </py-script>`,
                }}
              /> */}

                <div className="w-24 h-13 overflow-hidden">
                  <img
                    src={UPM}
                    alt="UPM"
                    // priority
                  />
                </div>

                {/* <VscChromeClose size={27} color='gray' /> */}
              </div>
              <Divider style={{ margin: 0 }} />

              {/* BODY */}
              <div className="flex sm:flex-row flex-col w-full h-full items-center justify-evenly">
                {/* UPLOAD SECTION */}
                <div className="flex flex-col space-y-3 justify-center items-center bg-gray-300 sm:w-5/12 sm:h-5/6 p-3 m-3">
                  <div>
                    <Upload maxCount={1} {...props}>
                      <Button
                        icon={<UploadOutlined />}
                        className="col-end-6 col-span-1 bg-blue-500 text-white hover:bg-blue-400"
                        type="primary"
                        block
                      >
                        Click to Upload
                      </Button>
                    </Upload>
                  </div>

                  {/* <div className="form-container">
                  <div
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                      handleDragDropEvent(e);
                      setFiles(e, 'a');
                    }}
                  >
                    <p>Drag and drop files here</p>

                    <Button className='col-end-6 col-span-1 bg-blue-500 text-white hover:bg-blue-400' type="primary" block onClick={() => inputRef.current.click()}>Or select files to upload</Button>

                    <input
                      ref={inputRef}
                      name='dataset'
                      type="file"
                      // multiple
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        setFiles(e, 'a');
                        console.log('e', e)
                        inputRef.current.value = null;
                      }}
                    />
                  </div>
                  <div className="submit">
                    <Button className='col-end-6 col-span-1 bg-blue-500 text-white hover:bg-blue-400' type="primary" block onClick={handleSubmitFile}>
                      Submit
                    </Button>
                  </div>
                </div> */}

                  {/* <input type={"file"} accept={".csv"} /> */}
                  {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  onChange={handleChangeFile}
                  // type={"file"}
                  // id={"csvFileInput"}
                  // accept={".csv"}
                >
                  {dataset ? (
                    <div>Uploaded!</div>
                  ) : (
                    uploadButton
                  )}
                </Upload> */}

                  {/* {
                  table
                } */}

                  {/* <Button type="primary" onClick={showModal}>
                  Show the datset
                </Button> */}

                  {/* <AiOutlinePlus size={50} color='gray' onClick={openNotification} className='cursor-pointer' />
                <div className='flex justify-center items-center sm:w-full sm:px-3 px-3 text-center'>Upload the dataset with missing values</div> */}
                </div>
                {/* OPTIONS SECTION */}
                <div className="flex flex-col sm:w-5/12 h-5/6">
                  <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="Options" key="1" className=" space-y-4">
                      <div className="flex flex-col space-y-5">
                        <div className="flex flex-col space-y-1">
                          <Text strong>Sampling Technique</Text>
                          <Select
                            defaultValue="Stratify"
                            style={{ width: 120 }}
                            onChange={handleChange}
                          >
                            <Option value="stratify">Stratify</Option>
                            <Option value="smoteenn">SMOTEENN</Option>
                          </Select>
                        </div>

                        <div className="flex flex-col space-y-1">
                          <Text strong>Feature Scaling Method</Text>
                          <Select
                            defaultValue="Standardization"
                            style={{ width: 150 }}
                            onChange={handleChange}
                          >
                            <Option value="stratify">Standardization</Option>
                            <Option value="normalization">Normalization</Option>
                          </Select>
                        </div>

                        <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                          <div className="flex flex-col space-y-1">
                            <Text strong>Number of trees</Text>
                            <Select
                              defaultValue="100"
                              style={{ width: 120 }}
                              onChange={handleChange}
                            >
                              <Option value="10">10</Option>
                              <Option value="20">20</Option>
                              <Option value="50">50</Option>
                              <Option value="100">100</Option>
                            </Select>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Text strong>Best split strategy</Text>
                            <Select
                              defaultValue="Auto"
                              style={{ width: 120 }}
                              onChange={handleChange}
                            >
                              <Option value="auto">Auto</Option>
                              <Option value="sqrt">Square root</Option>
                              <Option value="log2">log2</Option>
                              <Option value="none">None</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="flex sm:flex-row flex-col sm:space-x-4 space-x-0 sm:space-y-0 space-y-4">
                          <div className="flex flex-col space-y-1">
                            <Text strong>Traning set</Text>
                            <InputNumber
                              min={1}
                              max={100}
                              value={training}
                              onChange={onChangeTrainingSet}
                            />
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Text strong>Test set</Text>
                            <InputNumber
                              min={1}
                              max={100}
                              value={test}
                              onChange={onChangeTestSet}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row space-x-4 items-center">
                          <Button
                            className="flex  bg-blue-500 text-white hover:bg-blue-400"
                            type="primary"
                            onClick={handleImpute}
                          >
                            Impute
                          </Button>

                          <div className="flex bg-blue-500 px-3 h-8 rounded-sm text-white justify-center items-center">
                            <CSVLink
                              data={table}
                              separator={","}
                              // enclosingCharacter={`"`}
                            >
                              Export to CSV
                            </CSVLink>
                          </div>

                          <Button
                            className="flex  bg-blue-500 text-white hover:bg-blue-400"
                            type="primary"
                            onClick={() => navigate("/auto")}
                          >
                            Autonomous Imputation
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tab="Description" key="2">
                      <div className="flex flex-col space-y-3">
                        <Text>
                          Number of features:{" "}
                          {datasetFeatures ? datasetFeatures : "-"}
                        </Text>
                        <Text>
                          Number of instances:{" "}
                          {datasetInstances ? datasetInstances : "-"}
                        </Text>
                        <Text>Type of data: {dataType ? dataType : "-"}</Text>
                        <Text>
                          Missingness ratio:{" "}
                          {missingRatio ? missingRatio + "%" : "-"}
                        </Text>
                        <Text>
                          Size of data: {dataSize ? dataSize + "MB" : "-"}
                        </Text>
                        <Text>File format: {table ? "CSV Document" : "-"}</Text>
                      </div>
                    </TabPane>
                    <TabPane tab="Advanced" key="3">
                      <div className="flex flex-col space-y-6 justify-start items-start">
                        <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                          <div className="flex flex-col space-y-1">
                            <Text strong>Number of attributes</Text>
                            <InputNumber
                              min={1}
                              max={100000}
                              value={attributes}
                              style={{ width: 150 }}
                              onChange={onChangeAttributesSet}
                            />
                          </div>
                          <div className="flex flex-col space-y-1">
                            <Text strong>Number of instances</Text>
                            <InputNumber
                              min={1}
                              max={100000}
                              value={instances}
                              style={{ width: 150 }}
                              onChange={onChangeInstancesSet}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col space-y-4">
                          <Checkbox
                            style={{ paddingLeft: 8 }}
                            onChange={onChangeCheckBox}
                          >
                            Calculate RMSE for numerical features?
                          </Checkbox>
                          <Checkbox onChange={onChangeCheckBox}>
                            Calculate MAE for numerical features?
                          </Checkbox>
                          <Checkbox onChange={onChangeCheckBox}>
                            Calculate Accuracy for categorical features?
                          </Checkbox>
                          <Checkbox onChange={onChangeCheckBox}>
                            Calculate Precision for categorical features?
                          </Checkbox>
                          <Checkbox onChange={onChangeCheckBox}>
                            Calculate PFC for categorical features?
                          </Checkbox>
                          <Checkbox onChange={onChangeCheckBox}>
                            Calculate F-Score for categorical features?
                          </Checkbox>
                          {/* <Checkbox defaultChecked onChange={onChangeCheckBox}>Apply the Multicollinearity solution?</Checkbox> */}
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </main>

      {/* <footer className={styles.footer}>
        Footer
      </footer> */}

      <Modal
        width={"100%"}
        title="Dataset"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          className="flex overflow-auto"
          dangerouslySetInnerHTML={{
            __html: table,
          }}
        />
      </Modal>
    </div>
  );
};

export default Home;
