import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import AutoImpute from './pages/AutoImpute'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/auto' element={<AutoImpute />}></Route>
    </Routes>
  )
}

export default App