import { Button, Col, Divider, Form, Input, Row, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

import UPM from "../assets/images/upm-logo.png";

const AutoImpute = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [autoLoading, setAutoLoading] = useState(false);
  const [autoImputeOn, setAutoImputeOn] = useState(false);
  const [data, setData] = useState([]);
  // const [age, setAge] = useState(null)
  // const [gender, setGender] = useState(null)
  // const [diagnosis, setDiagnosis] = useState(null)
  const [flag, setFlag] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);

    try {
      let formData = new FormData();
      formData.append(
        "Diagnosis",
        values.Diagnosis === undefined ? "" : values.Diagnosis
      );
      formData.append("Age", values.Age === undefined ? "" : values.Age);
      formData.append(
        "PTGENDER",
        values.PTGENDER === undefined ? "" : values.PTGENDER
      );
      formData.append(
        "PTEDUCAT",
        values.PTEDUCAT === undefined ? "" : values.PTEDUCAT
      );
      formData.append(
        "PTETHCAT",
        values.PTETHCAT === undefined ? "" : values.PTETHCAT
      );
      formData.append(
        "PTRACCAT",
        values.PTRACCAT === undefined ? "" : values.PTRACCAT
      );
      formData.append(
        "PTMARRY",
        values.PTMARRY === undefined ? "" : values.PTMARRY
      );
      formData.append("CDRSB", values.CDRSB === undefined ? "" : values.CDRSB);
      formData.append(
        "ADAS11",
        values.ADAS11 === undefined ? "" : values.ADAS11
      );
      formData.append(
        "ADAS13",
        values.ADAS13 === undefined ? "" : values.ADAS13
      );
      formData.append("autoImputeOn", autoImputeOn);
      // .post("https://autoimpute-93lt.onrender.com/insertdata", formData, {
      axios
        .post("http://172.16.243.226:80/insertdata", formData, {
          "content-type": "multipart/form-data",
        })
        .then((res) => {
          // console.log('_id' ,res.data._id.$oid)
          setData(res.data);
          // if (autoImputeOn) {
          //     setData(res.data)
          // } else {
          //     setFlag(!flag)
          // }
          // console.log('res', res.data)
          setLoading(false);
          message.success(`The data has been inserted successfully!`);
          // saveAs(res.data, "df.csv");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          // message.error('Ooops.. it seems something went wrong! Please try again.');
        });
    } catch (error) {
      console.error("Failed to submit files.");
      setLoading(false);
      // message.error('Ooops.. it seems something went wrong! Please try again.');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setLoading(true);
    try {
      // .get("https://autoimpute-93lt.onrender.com/data")
      axios
        .get("http://172.16.243.226:80/data")
        .then((res) => {
          setData(res.data);
          // console.log('res', res.data)
          setLoading(false);
          // message.success(`The dataset ${dataset.name} has been imputed successfully!`);
          // saveAs(res.data, "df.csv");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          // message.error('Ooops.. it seems something went wrong! Please try again.');
        });
    } catch (error) {
      console.error("Failed to submit files.");
      setLoading(false);
      // message.error('Ooops.. it seems something went wrong! Please try again.');
    }
  }, []);

  const columns = [
    {
      title: "Diagnosis",
      dataIndex: "Diagnosis",
      key: "Diagnosis",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
    },
    {
      title: "PTGENDER",
      dataIndex: "PTGENDER",
      key: "PTGENDER",
    },
    {
      title: "PTEDUCAT",
      dataIndex: "PTEDUCAT",
      key: "PTEDUCAT",
    },
    {
      title: "PTETHCAT",
      dataIndex: "PTETHCAT",
      key: "PTETHCAT",
    },
    {
      title: "PTRACCAT",
      dataIndex: "PTRACCAT",
      key: "PTRACCAT",
    },
    {
      title: "PTMARRY",
      dataIndex: "PTMARRY",
      key: "PTMARRY",
    },
    {
      title: "CDRSB",
      dataIndex: "CDRSB",
      key: "CDRSB",
    },
    {
      title: "ADAS11",
      dataIndex: "ADAS11",
      key: "ADAS11",
    },
    {
      title: "ADAS13",
      dataIndex: "ADAS13",
      key: "ADAS13",
    },
  ];

  const handleAutoImpute = () => {
    setAutoLoading(true);
    setAutoImputeOn(true);
    try {
      // let formData = new FormData()
      // formData.append('dataset', dataset)
      axios
        // .post("https://autoimpute-93lt.onrender.com/autoimpute")
        .post("http://172.16.243.226:80/autoimpute")
        .then((res) => {
          // setTable(res.data)
          // console.log('res', res.data)
          setAutoLoading(false);
          message.success(`The autonomous imputation has been stopped!`);
          // saveAs(res.data, "df.csv");
        })
        .catch((err) => {
          console.log(err);
          setAutoLoading(false);
          message.error(
            "Ooops.. it seems something went wrong! Please try again."
          );
        });
    } catch (error) {
      console.error("Failed to submit files.");
      setAutoLoading(false);
      message.error("Ooops.. it seems something went wrong! Please try again.");
    }
  };

  const handleStopAutoImpute = () => {
    setLoading(true);
    setAutoImputeOn(false);
    try {
      // let formData = new FormData()
      // formData.append('dataset', dataset)
      axios
        // .post("https://autoimpute-93lt.onrender.com/stopautoimpute")
        .post("http://172.16.243.226:80/stopautoimpute")
        .then((res) => {
          // setTable(res.data)
          // console.log('res', res.data)
          setLoading(false);
          message.success(`The autonomous imputation has been stopped!`);
          // saveAs(res.data, "df.csv");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error(
            "Ooops.. it seems something went wrong! Please try again."
          );
        });
    } catch (error) {
      console.error("Failed to submit files.");
      setLoading(false);
      message.error("Ooops.. it seems something went wrong! Please try again.");
    }
  };

  return (
    <div>
      <div>
        <title>ImputeX | UPM</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </div>

      <main>
        {/* <Nav /> */}
        {/* <Spin size="large" spinning={loading} tip='Loading...'> */}
        <div className="flex justify-center items-center w-full h-screen bg-cyan-600">
          <div className="flex flex-col w-11/12 sm:h-5/6 py-5 bg-white shadow-xl rounded-sm">
            <div className="flex flex-row px-14 py-8 justify-between w-full">
              <div className="animate-pulse text-2xl">AutoImputeX</div>

              <div className="w-24 h-13 overflow-hidden">
                <img
                  src={UPM}
                  alt="UPM"
                  // priority
                />
              </div>

              {/* <VscChromeClose size={27} color='gray' /> */}
            </div>
            <Divider style={{ margin: 0 }} />

            {/* BODY */}
            <div className="flex flex-col w-full h-full justify-start space-y-5">
              {/* UPLOAD SECTION */}

              <div className="flex flex-row space-x-5 px-5 mt-3">
                <Form
                  name="record"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="Diagnosis"
                        name="Diagnosis"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="Diagnosis" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="Age"
                        name="Age"

                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the age!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="Age" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="PTGENDER"
                        name="PTGENDER"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the gender!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="PTGENDER" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="PTEDUCAT"
                        name="PTEDUCAT"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="PTEDUCAT" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="PTETHCAT"
                        name="PTETHCAT"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="PTETHCAT" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* SECOND ROW */}
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="PTRACCAT"
                        name="PTRACCAT"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="PTRACCAT" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="PTMARRY"
                        name="PTMARRY"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the age!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="PTMARRY" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="CDRSB"
                        name="CDRSB"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the gender!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="CDRSB" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="ADAS11"
                        name="ADAS11"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="ADAS11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item
                        label="ADAS13"
                        name="ADAS13"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input the diagnosis result!',
                        //     },
                        // ]}
                      >
                        <Input placeholder="ADAS13" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      className="bg-blue-500 text-white hover:bg-blue-400"
                      type="primary"
                      htmlType="submit"
                    >
                      Insert
                    </Button>
                  </Form.Item>
                </Form>
              </div>

              <div className="w-full">
                <Table
                  dataSource={data}
                  columns={columns}
                  scroll={{ y: 220 }}
                />
              </div>

              <div className="flex justify-between px-10 space-x-5">
                <Button
                  className="bg-blue-500 text-white hover:bg-blue-400"
                  type="primary"
                  onClick={() => navigate("/")}
                >
                  Back to Standard Imputation
                </Button>
                <div className="flex flex-row space-x-5">
                  <Button
                    loading={autoLoading}
                    className="bg-blue-500 text-white hover:bg-blue-400"
                    type="primary"
                    onClick={handleAutoImpute}
                  >
                    Start Autonomous Imputation
                  </Button>
                  <Button
                    className="bg-blue-500 text-white hover:bg-blue-400"
                    type="primary"
                    onClick={handleStopAutoImpute}
                  >
                    Stop Autonomous Imputation
                  </Button>

                  <div className="flex bg-blue-500 px-3 h-8 rounded-sm text-white justify-center items-center">
                    <CSVLink data={data} separator={","}>
                      Export
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Spin> */}
      </main>
    </div>
  );
};

export default AutoImpute;
